import { Link, Outlet, useLocation } from '@remix-run/react';
import { Heading, Text } from '@venncity/block';

export default function Login() {
  const location = useLocation();

  const isEmailRoute = location.pathname === '/auth/login';

  return (
    <>
      <header className="text-center">
        <Heading>Welcome</Heading>
      </header>
      <div className="mt-10">
        <Outlet />
      </div>
      <footer className="mt-6 flex flex-col items-stretch text-xs">
        <div className="flex items-center">
          <hr className="m-0 w-full text-white" />
          <Text className="mx-4 " variant="p6">
            or
          </Text>
          <hr className="m-0 w-full text-white" />
        </div>
        <Link
          className="hover:text-grey-900 mt-6 justify-center rounded-lg bg-transparent p-3 text-center text-base font-medium ring-1 ring-white transition-colors hover:bg-white"
          to={isEmailRoute ? 'phone' : '/auth/login'}>
          Login with {isEmailRoute ? 'phone' : 'email'}
        </Link>
        <Text className="mt-6" variant="p6">
          By logging in, I agree to the{' '}
          <a
            className="font-bold underline"
            href="https://legal.venn.city/app/privacy"
            rel="noopener noreferrer"
            target="_blank">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            className="font-bold underline"
            href="https://legal.venn.city/app/terms"
            rel="noopener noreferrer"
            target="_blank">
            T&C
          </a>
        </Text>
      </footer>
    </>
  );
}
